.menu-item {
  outline: 0;
  text-decoration: none;

  &.selected {
    border: 0.2rem solid transparent;

    .item-icon, .item-text {
      color: @primary-blue;
    }
  }

  .notification {
    height: var(--ax-badge-size);
    width: var(--ax-badge-size);
    .border-radius(50%);
    background-color: @ax-badge-unread;
    outline: 0.3rem solid transparent;
  }

  @import 'app/MenuAppItem.less';
}
